<template>
  <div ref="wrapper" :class="[$style.sticky, $style[position], $style[showState] ]" @touchstart.stop @touchend.stop @touchmove.stop >
    <slot :state="showState" :scrollY="scrollY" :visible="scrollVisible"></slot>
  </div>
</template>
<script lang=ts>
  import {BigBigWork} from "bbw-common";

  export default {
      name: "StickyBar",
      props: {
          /** 初始状态
           *  relative：     文档流
           *  fixed:         固定在顶部
           *  fixedHidden:   固定并隐藏
           * */
          startState:{
              type: String,
              required: false,
              default: 'fixed'
          },
          position:{
              type: String,
              required: false,
              default: 'top'
          },
          /** sticky布局边界DOM元素ID，滚动超过此元素则隐藏StickyBar
           *  #self: 把自己作为目标, 边界值为自己的容器高度
           *  [id]: 目标元素ID(例：#app)
           */
          stickyElm:{
              type: [String,null],
              required:false,
              default: null
          },
          /** sticky布局边界值（stickyElm不存在时才会使用）, 滚动超过此元素则隐藏StickyBar */
          stickyEdge:{
              type: Number,
              required:false,
              default:0
          },
          /** 滚动Y值 */
          scrollY:{
              type: Number,
              required: false,
              default:0
          },
          /** 滑动方向 */
          direction:{
              type: String,
              required: false,
              default: 'bottom'
          }
      },
      data(){
          return {
              /** 隐藏StickyBar的边界高度值 */
              hideEdge: Number.MAX_VALUE,
              /** 边界可视 */
              scrollVisible: false,
              /** 滚动方向可视 */
              directionVisible:true,
              /** 是否冻结 */
              freeze: false,
              /** 冻结保护计时器 */
              freezeTimer: null,
              /** 元素高度 */
              height: 0,
              /** 根据stickyElm选择的dom */
              stickyDom:null
          }
      },
      activated() {
          console.dir("StickyBar Activated");
          this.freeze = true;
          this.$emit("ChangeDirectionVisible", this.showState === 'fixed')
          this.handleDirection('bottom');
          this.freezeTimer = window.setTimeout( ()=> {
              this.freeze = false;
              this.handleScroll(this.scrollY)
              this.handleDirection('bottom');
          }, 500)
      },
      deactivated() {
          console.dir("StickyBar Deactivated");
      },
      mounted() {
          console.dir("StickyBar Mounted");
          this.entry();
      },
      destroyed() {
          this.exit();
      },
      methods:{
          entry(){
              try{
                  this.handleScroll(0);
                  this.dom = this.$refs.wrapper;
                  const rect = this.dom.getBoundingClientRect();
                  this.height = rect.height;
                  // 确定隐藏边界
                  if( this.stickyElm ){
                      window.setTimeout(()=>{
                          this.stickyDom = this.stickyElm === '#self' ? this.$refs.wrapper : document.getElementById( this.stickyElm ) ;
                          this.hideEdge = this.stickyDom?.clientHeight || 0;
                      },300)

                  }else{
                      this.hideEdge = this.stickyEdge;
                  }
              }catch (e) {
                  throw e;
              }

          },
          exit(){

          },
          /** 处理滚动  */
          handleDirection( direction:'top'|'bottom' ){
              if( this.freeze ) return;

              if( direction === 'bottom') {
                  this.directionVisible = true;

              }else{
                  this.directionVisible = false;
              }
            this.$emit("ChangeDirectionVisible", this.showState === 'fixed')
          },
          handleScroll( scrollY ){
              if( -scrollY < ( this.hideEdge || this.stickyEdge )){
                  this.scrollVisible = true
              }else{
                  this.scrollVisible = false
              }
              this.$emit("ChangeDirectionVisible", this.showState === 'fixed')
          }
      },
      computed:{
          showState(){
              const state = (this.scrollVisible || this.directionVisible) ? 'fixed' : 'fixedHidden';
              return this.freeze ? 'fixed' : state ;
          }
      },
      watch:{
          scrollY( newVal, oldVal ){
              this.handleScroll(newVal)
          },
          direction( newVal ){
              window.clearTimeout(this.freezeTimer);
              this.freeze = false;
              this.$nextTick(()=>{
                  this.handleDirection( newVal )
              })

          },
          showState( newVal, oldVal ) {
            BigBigWork.emit("updateState", newVal)
          }
      }
  }
</script>
<style lang=scss module src="./sticky-bar.scss"></style>
